body {
  text-shadow: #fff 0px 1px 1px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Font variant */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}

html * {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

#root {
  background: #ffffff;
  position: relative;
  z-index: 1;
}
